.table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;

    td, th {
        border: 1px solid #ddd;
        padding: 8px;
    }
  
    tr:nth-child(even){background-color: #f2f2f2;}
    
    tr:hover {background-color: #ddd;}
    
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #04AA6D;
        color: white;
    }
}

.text-overline {
    font-size: .75rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: .16667em;
}

/* Grid */
.column {
    flex-basis: 100%;
}
    
@media screen and (min-width: 800px) {
    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .col-1 {
        flex: 1;
    }
    .col-2 {
        flex: 2;
    }
    .col-3 {
        flex: 3;
    }
    .col-4 {
        flex: 4;
    }
    .col-5 {
        flex: 5;
    }
    .col-6 {
        flex: 6;
    }
    .col-7 {
        flex: 7;
    }
    .col-8 {
        flex: 8;
    }
    .col-9 {
        flex: 9;
    }
    .col-10 {
        flex: 10;
    }
}
