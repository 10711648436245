// Search container
.search-container {
    margin: 40px -20px 20px;
    @include tablet {
        margin: 40px 0 20px;
    }
    .modal--search & {
        margin: 0;
    }
    .card {
        background: $white;
        max-width: 660px;
        margin: 0 auto;
    }
}
.search__text-container {
    padding: 1px 30px 12px;
    text-align: center;
    @include large-phone {
        padding: 46px;
    }
}
.search__text {
    margin-top: 1px;
}

// Search Inputs
.watch-detail {
    box-sizing: border-box;
    position: relative;
    border-top: 1px solid $light-gray;
    flex: 1 1 50%;
    .start-date-container,
    .input--select {
        cursor: pointer;
        padding-top: 16px;
        padding-bottom: 16px;
        box-sizing: border-box;
        @include medium-phone {
            padding-right: 32px;
        }
        @include large-phone {
            padding-right: 48px;
            padding-top: 22px;
            padding-bottom: 22px;
        }
    }
    &:first-child {
        flex-basis: 100%;
    }
    &:nth-child( n + 3 ){
        border-left: 1px solid $light-gray;
    }
    &--restaurant {
        padding-top: 5px;
        padding-bottom: 5px;
        @include large-phone {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        &.invalid {
            box-shadow: inset 0 0 0px 1px $negative;
            animation: invalid 0.15s linear 2;
        }
    }
}


// Invalid animation
@keyframes invalid {
    0% {
        transform: translate( 0, 0 );
    }
    25% {
        transform: translate( -6px, 0 );
    }
    75% {
        transform: translate( 6px, 0 );
    }
    100% {
        transform: translate( 0, 0 );
    }
}

// Search button
.btn--search {
    width: 100%;
    border-radius: 0;
    padding: 16px 18px;
    @include large-phone {
        padding: 23px 18px;
    }
}

// Restaurant Finder
.search__finder {
    background: $darker-gray;
    padding: 10px 20px;
    text-align: center;
}
