.select {
    height: 30px;
    position: relative;
    border: 1px solid $light-gray;
    padding-right: 20px;
    &:focus {
        outline: none;
    }
    &::before,
    &::after {
        display: block;
        text-align: center;
        line-height: 30px;
    }
    &::after {
        content: attr( data-select );
    }
    .icon--carat {
        position: absolute;
        top: 8px;
        right: 14px;
        width: 2px;
        height: 10px;
    }
    &.select--results {
        display: inline-flex;
        min-width: 170px;
        justify-content: center;
        &::before,
        &::after {
            text-transform: lowercase;
            padding: 0;
            margin-right: 4px;
        }
        &::before {
            content: "sort by:";
        }
    }
    &.select--vendors {
        width: 70px;
        padding: 0 10px;
        padding-right: 20px;
        margin-left: 10px;
        &::before {
            content: 'Select:';
        }
        &::after {
            content: none;
        }
    }
    &.select--search {
        padding: 20px;
        position: relative;
        border: 0;
        &::after {
            padding: 0 10px;
        }
        .icon--carat {
            top: 26px;
            right: 30px;
            @include large-phone {
                top: 24px;
                right: 18px;
            }
        }
        @include large-phone {
            padding: 15px;
            padding-right: 30px;
            width: 124px;
            box-sizing: border-box;
            height: 100%;
            flex: 0 0 auto;
            background: $white;
            &::after {
                padding: 0;
            }
            &::before {
                @include before;
                height: 30px;
                width: 2px;
                background: $light-gray;
                top: 50%;
                left: 0;
                transform: translate( 0, -50% );
            }
        }
    }
}
.options {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: -1px;
    width: 100%;
    background: $white;
    z-index: 3;
    transition: all 300ms;
    .select.active & {
        visibility: visible;
        opacity: 1;
        border: 1px solid $light-gray;
    }
    &.options--search {
        top: 50%;
        transform: translate( 0, -50% );
        .select--search.active & {
            border: 0;
            @include large-phone {
                box-shadow: 0 2px 8px transparentize( $black, 0.8 );
            }
        }
    }
}
.option {
    text-transform: lowercase;
    padding: 10px;
    line-height: 20px;
    cursor: pointer;
    &.selected,
    &:hover {
        font-weight: bold;
    }
    &:hover {
        background: $light-gray;
    }
    &.option--search {
        padding: 20px;
        text-transform: none;
        @include large-phone {
            padding: 10px 20px;
        }
    }
}
