.menu {
    margin: 0;
    padding: 0;
    list-style: none;
}
.menu__link {
    text-decoration: none;
    display: inline-block;
}

// primary
.menu--primary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.menu__item--primary {
    box-sizing: border-box;
}
.menu__item--primary--last {
    @extend .menu__item--primary;
    margin: 0;
}
.menu__link--primary {
    @extend .menu__link;
    text-align: center;
    padding: 10px;
    &.btn--menu {
        padding: 10px 18px;
    }
}

// Header Collapsable menu
.toggle--menu {
    + .header__menu {
        visibility: hidden;
        opacity: 0;
        @include large-phone {
            visibility: visible;
            opacity: 1;
            z-index: 100;
        }
    }
    &.open + .header__menu {
        visibility: visible;
        opacity: 1;
        z-index: 100;
    }
}

.header__menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: transparentize( $black, 0.4 );
    transition: all 0.6s;
    height: 100vh;
    @include large-phone {
        height: auto;
        position: relative;
        top: 0;
        left: 0;
        width: auto;
        background: none;
    }
    .menu--primary {
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        background: $white;
        padding: 12px 20px 24px;
        @include large-phone {
            padding: 0;
            border: 0;
            background: none;
            justify-content: flex-end;
        }
    }
    .menu__item--primary {
        flex: 1 1 100%;
        margin: 0;
        @include large-phone {
            flex: 0 0;
            order: 2;
        }
    }
    .menu__item--primary--last {
        border: 0;
        margin: 0;
        white-space: nowrap;
        @include large-phone {
            order: 1;
            flex: 1 0 100%;
            text-align: right;
            a {
                display: inline-block;
            }
        }
        @include desktop-plus {
            order: 5;
            flex: 0 0 auto;
            margin-left: 8px;
        }

    }
    .menu__link--primary {
        text-align: left;
        padding: 12px 24px;
        &:hover {
             color: $light-gray;
        }
        &.btn--menu {
            border-width: 0;
            font-weight: bold;
            &:hover {
                background: none;
                color: $light-gray;
            }
        }
        @include large-phone {
            padding: 10px;
            margin-bottom: 10px;
            &.btn--menu {
                border-width: 1px;
                font-weight: normal;
                &:hover {
                    background: $primary;
                    color: $white;
                }
            }
        }
    }
}

.footer__menu {
    @include tablet {
        flex: 1 0 auto;
        flex-wrap: wrap;
        .menu--primary {
            justify-content: space-between;
        }
    }
    @include desktop {
        width: auto;
        .menu--primary {
            justify-content: flex-end;
        }
        .menu__item--primary--last {
            margin-left: 8px;
        }
    }
}

// Social
.menu--social {
    @extend .menu;
    justify-content: center;
    @include large-phone {
        justify-content: flex-start;
    }
}
.menu__item--social {
    margin-right: 8px;
}
.menu__link--social {
    @extend .menu__link;
    display: block;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    box-sizing: border-box;
    background: $light-gray;
    position: relative;
    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50% );
    }
}

// Sitelinks
.menu--sitelinks {
    @extend .menu;
}
.menu__item--sitelinks {
    margin-right: 8px;
}
.menu__link--sitelinks {
    @extend .menu__link;
    padding: 0 5px;
}
