.blog-layout {
    flex-wrap: wrap;
    @include desktop {
        flex-wrap: nowrap;
    }
    .pager-holder {
        flex: 1 1 calc( 100% - 20px );
        margin: 0 10px;
    }
}
.page__content {
    flex: 1 1 100%;
    @include desktop {
        flex-basis: 69.4117647059%
    }
}
.blog-listing {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    @include desktop {
        flex-basis: 69.4117647059%
    }
}

.blog-layout {
    .blog-item {
        width: 100%;
        margin-top: 0;
        margin-bottom: 30px;
        max-width: 100%;
        &:nth-child( 3n + 1 ),
        &:last-of-type:nth-child( 3n + 2 ) {
            max-width: 100%;
            flex: 1 1 100%;
            .blog-item__image-container {
                padding: 0;
                height: auto;
                .blog-item__image {
                    &--blur {
                        display: none;
                    }
                    &--clear {
                        position: static;
                        transform: none;
                        box-shadow: none;
                        display: block;
                    }
                }
            }
        }
        @include large-phone {
            flex-basis: calc( 50% - 20px );
            max-width: calc( 50% - 20px );
        }
        @include desktop {
            flex-basis: calc( 50% - 20px );
        }
    }

    .blog-item__image-container {
        width: 100%;
        padding-top: 58.3333333333%; // 700/1200
        height: 0;
        margin: 0;
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
        // for home page and sidebar
        background-size: cover;
    }
    .blog-item__image {
        &--blur {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            filter: blur( 5px );
            opacity: 0.3;
            transform: scale( 1.05 );
            &::before {
                @include before;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: rgba( 0,0,0,0.2 );
            }
        }
        &--clear {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate( -50%, -50% );
            box-shadow: 0px 0px 10px transparentize( $black, 0.7 );
            max-height: 100%;
            @include tablet {
                width: 100%;
            }
        }
    }
    .blog-item__text {
        padding: 20px;
    }

    .blog-item__title {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: normal;
    }

    .blog-item__byline {
        font-size: 14px;
        color: darken( $light-gray, 10% );
        text-align: right;
        margin-top: 10px;
    }
}

.content--article {
    padding-bottom: 0;
    @include tablet {
        padding-bottom: 60px;
    }
}
.post {
    padding-bottom: 40px;
    h4, h5, h6 {
        font-weight: bold;
        font-size: 1.2em;
        margin: 20px 0 16px;
    }
}

.post__image-container {
    margin-bottom: 20px;
    img {
        display: block;
        width: 100%;
    }
}
.post__title {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid $dark-gray;
}
.post__byline {
    margin-bottom: 20px;
}
