html {
    background: $white;
}
body {
    min-width: 320px;
    background: $white;
    min-height: 100vh;
}
// TODO revisit this
.body-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    > div {
        flex: 0 0 auto;
    }
    #content {
        flex: 1 0 auto;
        // min-height: 400px;
        display: flex;
        flex-direction: column;
        > div {
            flex: 1 1 auto;
        }
    }
    &.menu-open {
        height: 100vh;
        overflow: hidden;
        @include large-phone {
            height: auto;
            overflow: auto;
        }
    }
    &.modal-open {
        // height: 100vh;
        // width: 100vw;
        // overflow: hidden;
        // position: fixed;
    }
}
.width-wrapper,
.wrapper {
    max-width: 1300px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin: 0 auto;
    @include tablet {
        padding: 0 30px;
    }
    @include desktop {
        padding: 0 40px;
    }
    .to-edge {
        margin-left: -20px;
        margin-right: -20px;
        @include tablet {
            margin-left: -30px;
            margin-right: -30px;
        }
        @include desktop {
            margin-left: 0;
            margin-right: 0;
            position: relative;
            > * {
                z-index: 2;
            }
            &::before{
                @include before;
                width: 100vw;
                height: 100%;
                background: inherit;
                border: inherit;
                z-index: 1;
                top: 0;
                left: 50%;
                transform: translate( -50%, 0 );
            }
        }
    }
}
.top-container {
    background: $white;
}

section {
    padding: 30px 0;
    @include large-phone {
        padding: 48px 0;
    }
}

img {
    max-width: 100%;
    height: auto;
}
.img-container {
    img {
        display: block;
    }
}
.hide-on-internal {
    visibility: hidden;
    opacity: 0;
    .home & {
        visibility: visible;
        opacity: 1;
    }
}
