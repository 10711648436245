.pagination {
    padding: 5px 10px;
    display: inline-block;
  
    span.info {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
    }

    a {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color .3s;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin: 0 4px;
    }
  
    a.active {
        background-color: #4CAF50;
        border-radius: 5px;
        color: white;
        border: 1px solid #4CAF50;
    }
  
    a:hover:not(.active) {
        background-color: #ddd;
        border-radius: 5px;
    }
}