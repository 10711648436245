.account-settings-page {
    // Tabbed Content
    background: $off-white;
    border-bottom: 1px solid $light-gray;
    @include tablet {
        border-top: 1px solid $light-gray;
    }
    .tabbed-content {
        max-width: 800px;
        padding: 0 20px;
        @include tablet {
            padding: 0 40px;
        }
        @include desktop {
            padding: 0 60px;
        }
    }

    // Account toggle
    section,
    .account-toggle {
        flex: 1 1 100%;
    }
    .account-toggle {
        display: flex;
        @include tablet {
            flex: 1 1 30%;
            display: block;
            background: $white;
            position: relative;
            padding-top: 48px;
            min-height: 60vh;
        }
    }
    .toggleable {
        min-height: 60vh;
        @include tablet {
            flex: 1 1 70%;
        }
    }
    .accounts-hidden {
        display: none;
    }
    .toggle__account {
        flex: 1 1 50%;
        padding: 20px 10px;
        box-sizing: border-box;
        background: $primary;
        text-align: center;
        display: block;
        @include tablet {
            text-align: right;
            padding: 4px 40px;
            background: none;
        }
        @include desktop {
            padding-right: 80px;
        }
    }
    .toggle--active {
        background: lighten( $primary, 10% );;
        color: $off-white;
        @include tablet {
            background: none;
            color: $dark-gray;
            font-weight: bold;
        }
    }

    // Form inputs
    .user-account-form {
        justify-content: space-between;
    }

    .form__element {
        flex-basis: 100%;
        margin-bottom: 20px;
        .input--text {
            line-height: 20px;
            height: 40px;
        }
        @include tablet {
            flex-basis: calc( 50% - 10px );

        }
    }
    .input__wrapper--with-icon {
        background: $white;
        input {
            &:focus {
                &.inplace-input {
                    padding-right: 8px;
                    + .icon {
                        display: none;
                    }
                }
            }
        }
    }

    .label {
        font-weight: bold;
        &:not( .checkbox__label ){
            flex: 0 0 130px;
        }
        &.since {
            @include tablet {
                flex: 0 0 auto;
                margin-right: 10px;
            }
        }
    }
    .details {
        p:not( .false-input ) {
            margin-bottom: 6px;
        }
    }
    .small-text {
        margin-top: 6px;
    }

    // Pro Callout
    .pro-callout {
        background: $white;
        padding: 20px;
        box-shadow: 0 0 10px transparentize($light-gray, 0.7);
        border-radius: 2px;
        .title {
            text-align: center;
        }
        .btn-container {
            text-align: center;
            margin-top: 20px;
        }
    }

    // Card display
    .details--payment p {
        margin-bottom: 4px;
    }
    .cc__details {
        display: flex;
    }
    .cc__logo {
        height: 20px;
        width: 32px;
        margin-right: 5px;
        flex: 0 0 auto;
    }
    .cc__info {
        display: block;
        line-height: 20px;
    }

    // Payment history
    .payment-history {
        border: 1px solid $light-gray;
        background: $white;
        max-height: 190px;
        overflow: auto;
        margin: 10px 0 0;
    }
    .payment-event {
        padding: 5px;
        &:nth-child( even ){
            background: lighten( $light-gray, 10% );
        }
    }
}

// Current Method styles
.current-method {
    position: relative;
    &::after {
        @include before;
        transition: background 0.6s, height 0s linear 0.6s, width 0s linear 0.6s;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        background: transparentize( $positive, 1 );
        z-index: 0;
    }
    &.adding,
    &.removing {
        &::after {
            z-index: 100;
            height: 100%;
            width: 100%;
            background: transparentize( $positive, 0.5 );
        }
    }
    &.removing {
        opacity: 0;
        transition: opacity 0.6s;
        &::after {
            transition: none;
            background: transparentize( $negative, 0.5 );
        }
    }
}
.verification-status {
    display: block;
    margin-bottom: 6px;
}
.verified {
    color: $positive;
    font-weight: bold;
    .icon {
        margin-left: 0;
    }
}
.method-toggle {
    color: $light-gray;
    display: flex;
}

// Add Method styles
.method__text-container {
    width: 100%;
    display: flex;
    position: relative;
    .method--country-select {
        flex: 0 0 130px;
        margin-left: 0;
        margin-right: 20px;
        position: relative;
        select {
            padding-right: 20px;
        }
    }
    .method--number {
        flex: 1 1;
    }
}
.add-methods {
    .input__wrapper--with-icon {
        a {
            background: $primary;
            color: $white;
            padding: 10px;
            right: -1px;
            height: calc( 100% + 2px );
            box-sizing: border-box;
            border-radius: 3px;
            &::before {
                vertical-align: middle;
            }
        }
    }
}

// Verify modal
.modal--verify {
    .input__wrapper--border {
        max-width: 150px;
        margin-right: 20px;
    }
}
.verify--method {
    text-align: center;
}
