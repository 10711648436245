body {
    color: $dark-gray;
    @include font($text);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    @include tablet {
        font-size: 18px;
    }
}
h1, h2, h3, h4, h5, h6 {
    @include font($headline);
}

h1 {
    font-size: 28px;
    font-weight: bold;
    margin: 9px 0 9px 0;
    @include tablet {
        font-size: 36px;
    }
}

h2 {
    font-size: 21px;
    font-weight: bold;
    margin: 11px 0 11px 0;
    @include tablet {
        font-size: 28px;
    }
}

h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 14px 0 14px 0;
    @include tablet {
        font-size: 22px;
    }
}

strong {
    font-weight: 700;
}
a, a:hover {
    text-decoration: none;
    color: inherit;
}
.italic {
    font-style: italic;
}
.content a:not( .styled-link, .btn ) {
    color: $primary;
}
.content ul:not( .styled-list ) {
    list-style: disc;
    margin: 20px 0;
    padding-left: 30px;
    li {
        margin-bottom: 10px;
    }
}
.content p:not( .styled-p ) {
    margin-bottom: 12px;
}
.content {
    .page__content ol:not( .styled-list ) {
        list-style-type: decimal;
        list-style-position: inside;
        margin-left: 15px;
        li {
            margin-bottom: 10px;
        }
    }
}

.white-text {
    color: $white;
}
.primary-text {
    color: $primary;
}
.small-text {
    font-size: 11px;
    line-height: 1.1;
}

// Section titles
.section__title {
	font-size: 28px;
    letter-spacing: 0.39px;
    text-align: center;
    margin-bottom: 10px;
    @include tablet {
        font-size: 36px;
        letter-spacing: -0.68px;
        font-weight: normal;
        text-align: left;
        margin-bottom: 20px;
    }
}
.section__subtitle {
    font-size: 28px;
    letter-spacing: -0.68px;
    text-align: center;
    margin: 20px 0;
    @include tablet {
        font-size: 36px;
    }
}

// Navigation
.menu__link--primary {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 1px;
    font-weight: 300;
    .header__menu & {
        font-size: 36px;
        letter-spacing: -0.6px;
        text-transform: none;
        @include large-phone {
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 1px;
        }
    }
}

// Footer Sitelinks
.menu__link--sitelinks {
    font-size: 16px;
}

// Social menu
.menu__link--social {
    .icon {
        color: $white;
        &::before {
            font-size: 24px;
        }
    }
}

// Prefooter
.prefooter__title {
    font-size: 16px;
    letter-spacing: 1.14px;
    @include large-phone {
        font-size: 36px;
        letter-spacing: -0.68px;
    }
}
.prefooter__text {
    font-size: 14px;
    @include large-phone {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 1.4;
    }
}
.footer__disclaimer {
    font-size: 14px;
}

.btn {
    font-size: 16px;
    font-weight: 700;
}
.highlight {
    color: $primary;
}

// Inputs
.input__wrapper {
    font-size: 16px;
    @include tablet {
        font-size: 18px;
    }
}

.validate-wrapper {
    .message__text {
        font-size: 11px;
    }
}
.input--select {
    font-size: 16px;
    line-height: 1.3;
}

.label--labelize {
    &.labelize {
        font-size: 11px;
    }
}
.input__wrapper--remember {
    font-size: 12px;
}

// daterangepicker
.calendar {
    thead {
        font-weight: bold;
        font-size: 14px;
    }
    td {
        font-size: 14px;
    }
}

// Search module
.search__title {
    font-weight: 200;
    letter-spacing: -0.68px;
    line-height: 1.1;
    font-size: 32px;
    @include large-phone {
        font-size: 46px;
    }
    .primary-text {
        font-weight: bold;
    }
}
.watch-detail {
    font-size: 16px;
    .start-date-container,
    .input--select {
        font-size: inherit;
    }
    @include large-phone {
        font-size: 18px;
    }
}

// Home spotlight
.spotlight__heading {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    color: $light-gray;
    letter-spacing: 0.25px;
    line-height: 24px;
}
.spotlight__title {
    font-size: 30px;
    color: $white;
    letter-spacing: -0.57px;
    line-height: 1.1;
    @include large-phone {
        font-size: 36px;
        letter-spacing: -0.68px;
    }
}
.spotlight__details {
    font-size: 20px;
    color: $light-gray;
    letter-spacing: 0.28px;
    line-height: 28px;
    font-weight: bold;
}

// Restaurant Finder
.search__finder {
    background: $darker-gray;
    font-size: 18px;
    color: $off-white;
    letter-spacing: 0.25px;
    line-height: 24px;
}

// Restaurant hours
.restaurant__hours {
    .hours {
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-size: 16px;
        line-height: 1;
    }
}

.hours__title {
    font-weight: bold;
    font-size: 16px;
}

// Watchlist
.watch__date,
.watch__size {
    font-size: 18px;
    font-weight: bold;
}
.watch__restaurant {
    font-size: 24px;
    color: $light-gray;
    letter-spacing: -0.48px;
    font-weight: bold;
}
.watch__meal {
    font-size: 18px;
    color: $light-gray;
    letter-spacing: -0.36px;
}

// Account
.toggle__account {
    font-size: 18px;
    color: $white;
    @include tablet {
        color: $dark-gray;
    }
}
.cc__details {
    font-size: 14px;
}
.verification-status {
    font-size: 14px;
}

// modals
.modal__header {
    font-size: 30px;
    letter-spacing: -0.5px;
}
.alert__title {
    font-size: 20px;
    margin-bottom: 20px;
    @include tablet {
        font-size: 28px;
    }
}

// Pricing plan
.plan__name {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.25px;
}
