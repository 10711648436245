.btn {
    @include button;
}

.btn-separator {
    margin: 0 15px;
}

.btn i {
    position: relative;
    top: 1px;
}
.btn-group {
    display: flex;
    .btn {
        border-radius: 0;
        border: 1px solid $dark-gray;
        border-left-width: 0;
        background: transparentize( $dark-gray, 0.7 );
        color: $white;
        &:hover {
            opacity: 0.7;
        }
        &.toggle--active {
            background: $white;
            color: $dark-gray;
            font-weight: bold;
            opacity: 1;
            cursor: default;
        }
        &:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-left-width: 1px;
        }
        &:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }
}
