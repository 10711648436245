// whiteout
.whiteout {
    position: relative;
    overflow: hidden;
    z-index: 0;
    vertical-align: top;
    .whiteout__inner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
        box-sizing: border-box;
        .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate( -50%, -50% );
            &.atTop {
                top: 100px;
            }
        }
        .loading--text {
            font-size: inherit;
            padding-right: 16px;
            white-space: nowrap;
            display: inline-block;
            &::after {
                width: 16px;
                transform: translate( -16px, 0 );
            }
        }
    }
}

// Loading animation
@keyframes animatingEllipsis {
    from { width: 0 }
}

.loading--text {
    position: relative;
    &::after {
        position: absolute;
        left: 100%;
        top: 0;
        overflow: hidden;
        display: inline-block;
        animation: animatingEllipsis steps( 4 ) 1.4s infinite;
        content: "\2026";
        width: 1.25em;
    }
}
