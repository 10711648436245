// Unicode icons
.icon-arrow-left:before {
    font-family: 'Tahoma';
    content: "\2190";
}
.icon-arrow-right:before {
    font-family: 'Tahoma';
    content: "\2192";
}

.toggle--menu,
.toggle--search {
    display: block;
    width: 44px;
    height: 44px;
    position: relative;
    cursor: pointer;
}
.toggle--menu {
    @include large-phone {
        display: none;
    }
}
.toggle--search {
    margin-left: 0;
    margin-right: -10px;
    .icon--search {
        position: absolute;
        top: 10px;
        left: 14px;
    }
    @include large-phone {
        display: none;
    }
    .home & {
        display: none;
    }
}

// Menu Icon
.icon--menu {
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    width: 32px;
    display: block;
    background: $dark-gray;
    height: 2px;
    position: absolute;
    transition: all 400ms ease-in-out;
    &::before,
    &::after {
        @extend .icon--menu;
        content: '';
    }
    &::before {
        top: -8px;
    }
    &::after {
        top: 10px;
    }
    .open & {
        transform: translate( -50%, -50% ) rotate( 45deg );
        transform-origin: center center;
        &::before,
        &::after {
            transform: translate( -50%, -50% ) rotate( 90deg );
            transform-origin: center center;
            top: 2px;
        }
    }
}

// Search Icon
.icon--search {
    position: relative;
    border: 3px solid $dark-gray;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: inline-block;
    &::after {
        @include before;
        background: $dark-gray;
        height: 4px;
        transform: rotate( 45deg ) translate( -25%, -25% );
        transform-origin: center left;
        width: 100%;
        top: 100%;
        left: 100%;
    }
}

// Clear Icon
.icon--clear {
    position: relative;
    right: 0;
    top: 0;
    height: 16px;
    width: 16px;
    cursor: pointer;
    &::before,
    &::after {
        @include before;
        height: 100%;
        width: 4px;
        background: $light-gray;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50% ) rotate( 45deg );
        transform-origin: center center;
    }
    &::after {
        transform: translate( -50%, -50% ) rotate( -45deg );
    }
}

// Login icon
.icon--login {
    display: block;
    height: 1.4285714286em; // 20/14
    width: 1.4285714286em; // 20/14
    position: relative;
    background: $dark-gray;
    border: 3px solid $dark-gray;
    border-radius: 50%;
    overflow: hidden;
    &::before,
    &::after {
        @include before;
        background: $white;
        border-radius: 50%;
        height: 38%;
        width: 38%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate( -50%, 0 );
    }
    &::after {
        height: 100%;
        width: 100%;
        top: 50%;
    }
}

// carat icon
.icon--carat {
    display: block;
    height: 14px;
    width: 4px;
    position: relative;
    &::before,
    &::after {
        @include before;
        position: absolute;
        height: 100%;
        width: 100%;
        background: $dark-gray;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50% ) rotate( 45deg );
        transform-origin: bottom center;
    }
    &::after {
        transform: translate( 0, -50% ) rotate( -45deg );
    }
}

// pencil icon
.icon--pencil {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 4px;
    transform: rotate( 45deg );
    &::before,
    &::after {
        @include before;
        left: 0;
    }
    &:before {
        @include triangle( down, $dark-gray, 4, 4 );
        bottom: 0;
        width: 0;
        height: 0;
    }
    &::after {
        bottom: 3px;
        width: 4px;
        height: 10px;
        border-top: 1px solid $white;
        box-shadow: 0 0 0 3px $dark-gray;
        clip: rect( -3px, 4px, 10px, 0 );
        background: $dark-gray;
    }
}

// check icon
.icon--check {
    display: block;
    height: 20px;
    width: 20px;
    position: relative;
    &::before,
    &::after {
        @include before;
        bottom: 3px;
        left: 0;
        transform: translate( -50%, 0 ) rotate( 45deg );
        transform-origin: bottom right;
        background: $dark-gray;
        border-radius: 2px;
    }
    &::before {
        height: 16px;
        width: 4px;
        margin-left: 3px;
    }
    &::after {
        width: 10px;
        height: 4px;
    }
    &.icon--white {
        &::before,
        &::after {
            background: $white;
        }
    }
}

// bang icon
.icon--bang {
    display: block;
    height: 20px;
    width: 16px;
    &::before,
    &::after {
        @include before;
        width: 4px;
        background: $white;
        left: 50%;
        border-radius: 2px;
        transform: translate( -50%, 0 );
    }
    &::before {
        top: 0;
        height: 14px;
    }
    &::after {
        bottom: 0;
        height: 4px;
    }
}
