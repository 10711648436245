// Restaurant picker
.searchbar--home {
    position: relative;
	&.invalid {
		animation: invalid 0.15s linear 2;
		.input__wrapper--with-icon {
			box-shadow: inset 0 0 0px 1px $negative;
		}
	}
}
.search__results {
    position: absolute;
    top: 100%;
    width: 100%;
    background: $white;
    box-shadow: 0 2px 8px transparentize( $black, 0.8 );
    z-index: 10;
    .modal & {
        position: relative;
        top: 5px;
        @include large-phone {
            top: 10px;
        }
    }

}
.result {
    padding: 6px 10px;
    border-bottom: 1px solid $light-gray;
    &:hover {
        cursor: pointer;
        &:not( .result--selected ) {
            background: darken( $primary, 4% );
            color: $white;
        }
    }
}
.result--empty {
    background: $light-gray;
    padding: 19px 10px;
}
.result--selected {
    background: $primary;
    color: $white;
}
