.pager__container {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    @include medium-phone {
        width: auto;
        margin-left: 10px;
        text-align: right;
    }
}
.pager {
    padding: 0 10px;
    font-weight: bold;
    color: $primary;
    display: inline-block;
}
.pager--disabled {
    color: $light-gray;
    cursor: default;
    &:hover {
        color: $light-gray;
    }
}

.pager--page-size {
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
}
.page-size {
    color: $primary;
    padding: 0 2px;
}
.page-size--active {
    color: $dark-gray;
    cursor: default;
    font-weight: bold;
    &:hover {
        color: $dark-gray;
    }
}
