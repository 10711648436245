.overlay-container {
    position: relative;
    &::before,
    &::after {
        position: relative;
        z-index: 1;
    }
    .width-wrapper {
        position: relative;
        z-index: 2;
    }
}
