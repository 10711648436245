@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?hfyq09');
  src:  url('../../assets/fonts/icomoon.eot?hfyq09#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf?hfyq09') format('truetype'),
    url('../../assets/fonts/icomoon.woff?hfyq09') format('woff'),
    url('../../assets/fonts/icomoon.svg?hfyq09#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-size: 1em;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Helper classes
 */
.icon-rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.icon-rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.icon-rotate-270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.icon-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-flip-vertical {
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
.icon-mail-alert:before {
  content: "\e909";
}
.icon-discord:before {
  content: "\e91a";
}
.icon-alerts:before {
  content: "\e91b";
}
.icon-note-add:before {
  content: "\e91e";
}
.icon-edit:before {
  content: "\e906";
}
.icon-email:before {
  content: "\e900";
}
.icon-group:before {
  content: "\e907";
}
.icon-calendar:before {
  content: "\e908";
}
.icon-internet:before {
  content: "\e90a";
}
.icon-heart:before {
  content: "\e90b";
}
.icon-star:before {
  content: "\e90c";
}
.icon-check:before {
  content: "\e90d";
}
.icon-meal:before {
  content: "\e90e";
}
.icon-time:before {
  content: "\e90f";
}
.icon-cancel:before {
  content: "\e910";
}
.icon-facebook:before {
  content: "\e911";
}
.icon-instagram:before {
  content: "\e912";
}
.icon-phone:before {
  content: "\e913";
}
.icon-pinterest:before {
  content: "\e914";
}
.icon-plus:before {
  content: "\e915";
}
.icon-reddit:before {
  content: "\e916";
}
.icon-search:before {
  content: "\e917";
}
.icon-twitter:before {
  content: "\e918";
}
.icon-warning:before {
  content: "\e920";
}
.icon-date:before {
  content: "\e919";
}
.icon-cart-plus:before {
  content: "\e91d";
}
.icon-dollar:before {
  content: "\e901";
}
.icon-down:before {
  content: "\e902";
}
.icon-up:before {
  content: "\e903";
}
.icon-right:before {
  content: "\e904";
}
.icon-left:before {
  content: "\e905";
}
