.restaurant-item {
    position: relative;
}
.restaurant-item__title {
    flex-basis: calc( 100% - 120px );
}
.fav-indicator {
    position: absolute;
    top: 10px;
    right: 10px;
    &.icon-heart {
        color: $white;
        &.favorite {
            color: $primary;
        }
    }
}
