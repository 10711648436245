$black         : #000000;
$white         : #ffffff;
$off-white     : #F5f5f5;

$light-gray    : #C3C3C3;
$darker-gray   : #312F2B;
$dark-gray     : #6A6463;
$footer-gray   : #8C8C8C;

$primary       : #E14040;
$accent        : #DCAD69;

$positive      : #3FC380;
$negative      : #E14040;
$caution       : #f9d372;
