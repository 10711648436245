.watchlist__watch {
    min-width: 300px;
    &.watchlist__watch--inactive {
        background: $off-white;
    }
}
.watch__header {
    background: $off-white;
}
.watch__date,
.watch__size {
    box-sizing: border-box;
    padding: 10px 14px;
}
.watch__size {
    text-align: right;
}
.watch__content {
    padding: 10px 14px;
    text-align: center;
    flex: 1 1 auto;
    a {
        color: $primary;
    }
}
.watch__restaurant {
    margin: 5px 20px;
}
.watch__meal {
    margin: 0;
}

.action-bar {
    border-top: 1px solid $light-gray;
    display: flex;
    margin-top: auto;
}
.watch__action {
    flex: 1 1 50%;
    box-sizing: border-box;
    padding: 9px 14px;
    text-align: center;
    &:hover {
        color: darken( $light-gray, 10% );
    }
    &.watch__link--active {
        color: $primary;
    }
    & + & {
        border-left: 1px solid $light-gray;
    }
}
.watch__comment {
    margin-top: auto;
    resize: none;
    border: none;
    box-shadow: none;
    &:empty {
        background: none;
        height: 1px;
        padding: 0;
    }
    &,
    &:focus {
        height: 40px;
        background: transparentize($accent, 0.9);
        padding: 5px;
        margin-top: 5px;
    }
}
.watch__comment--error {
    color: $negative;
}

.alertlist__btns {
    margin-top: 20px;
    box-sizing: border-box;
    padding: 30px 0 20px;
    flex-wrap: wrap;
    border-top: 1px solid darken( $off-white, 5% );
    &.to-edge {
        &:before {
            top: -1px;
        }
    }

    .btn-separator {
        display: none;
    }
    .btn {
        margin: 10px;
    }
    @include large-phone {
        flex-wrap: nowrap;
        .btn-separator {
            display: block;
        }
        .btn {
            margin: 0;
        }
    }
}
