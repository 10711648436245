.collapsable {
    transition: visibility 0.6s, opacity 0.6s;
    overflow: hidden;
    visibility: visible;
    opacity: 1;
    &.collapsed {
        visibility: hidden;
        opacity: 0;
    }
}
