.feature__image {
    position: relative;
    img {
        width: 100%;
        display: block;
    }
    .width-wrapper {
        position: relative;
    }
    &.condense,
    &.content-feature {
        @include tablet {
            padding: 20px 24px;
            overflow: hidden;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate( -50%, -50% );
            }
            .return-link {
                position: relative;
                bottom: auto;
                left: auto;
                display: inline-block;
            }
        }
    }
    &.content-feature {
        @include tablet {
            max-height: 400px;
            padding: 25% 0 0;
            box-sizing: border-box;
        }
    }
}
.return-container {
    .content--menu & {
        background: transparentize( $darker-gray, 0.2 );
        .return-link {
            background: none;
        }
    }
}

.return-link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: transparentize( $darker-gray, 0.2 );
    color: $white;
    padding: 20px;
    box-sizing: border-box;
    display: block;
    z-index: 99;
    &.link-only {
        position: static;
        bottom: auto;
        left: auto;
    }
    @include tablet {
        position: absolute;
        width: auto;
        bottom: 20px;
        left: 20px;
        //z-index: 1000;
    }
}
.img-container {
    @include desktop-plus {
        text-align: -webkit-center;
        background-color: black;
    }
    .rslides {
        position: relative;
        list-style: none;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0;
        @include desktop-plus {
            width: 100%;
        }

        li {
            -webkit-backface-visibility: hidden;
            position: absolute;
            display: none;
            width: 100%;
            left: 0;
            top: 0;
        }

        li:first-child {
            position: relative;
            display: block;
            float: left;
        }

        img {
            display: block;
            height: auto;
            float: left;
            width: 100%;
            border: 0;
            @include desktop-plus {
                height: 500px;
                width: 70%;
                object-fit: fill;
                float: none;
            }
        }
    }
}
