.grid {
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include large-phone {
        margin: 0 -10px;
    }
}
.grid__item {
    margin: 10px;
    background: white;
    box-sizing: border-box;
    padding: 20px;
    flex: 0 1 100%;
    max-width: 360px;
    @include large-phone {
        flex-basis: calc( 50% - 20px );
    }
    @include desktop {
        flex-basis: calc( 33.333% - 20px );
    }
}
