.input__wrapper {
    position: relative;
    box-sizing: border-box;
}
.input__wrapper--border {
    @extend .input__wrapper;
    border: 1px solid $light-gray;
    border-radius: 3px;
    .input--text {
        border-radius: 3px;
    }
}
.input__wrapper--no-border {
    @extend .input__wrapper;
    border: 0;
}
.input__wrapper--remember {
    @extend .input__wrapper;
    margin: 10px 0;
}

.input--text {
    @include input;
}

.false-input {
    @extend .input--text;
    background: $off-white;
}
.input-loader {
    position: absolute;
    top: 50%;
    left: 48px;
    transform: translate( 0, -50% );
}

// Select input
.input__wrapper--select {
    display: block;
    position: relative;
    // border: 1px solid $light-gray;
    &::before,
    &::after {
        @include before;
        position: absolute;
        height: 10px;
        width: 1px;
        background: $dark-gray;
        top: 47%;
        right: 16px;
        transform: translate( 0, -50% ) rotate( 45deg );
        transform-origin: bottom center;
        @include medium-phone {
            right: 20px;
        }
    }
    &::after {
        transform: translate( 0, -50% ) rotate( -45deg );
    }
}
.input--select {
    outline: 0;
    border: none;
    appearance: none;
    background: none;
    border-radius: 0;
    padding: 10px;
    padding-right: 30px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    z-index: 3;
    color: inherit;
}

// Travel Agent Radio
.wrapper--radio {
    flex-wrap: wrap;
    .input__label {
        flex: 1 1 100%;
    }
    @include medium-phone {
        flex-wrap: nowrap;
        .input__label {
            flex: 0 0 auto;
        }
    }
}
.input--radio {
    margin-top: 5px;
    input {
        margin: -2px 10px 0;
        margin-left: 0;
    }
    + .input--radio {
        input {
            margin-left: 10px;
        }
    }
    @include medium-phone {
        margin-top: 0;
        input {
            margin-left: 10px;
        }
    }
}

// Labelize inputs
.input__wrapper--labelize {
    @extend .input__wrapper;
    border-bottom: 1px solid $light-gray;
    margin: 20px 0;
    &.input__wrapper--no-border {
        border: 0;
    }
}
.label--labelize {
    position: absolute;
    bottom: 6px;
    left: 0;
    transition: 0.2s all;
    &.labelize {
        bottom: 100%;
        margin-bottom: 3px;
        line-height: 1;
    }
}
.input__label {
    color: darken( $light-gray, 8% );
    line-height: 20px;
}
.input--labelize {
    padding: 6px 0;
    line-height: 20px;
    position: relative;
    z-index: 2;
    &:focus {
        outline: none;
        ~ .label--labelize {
            @extend .labelize;
        }
    }
}

// Styled Checkbox
.checkbox__input {
    position: absolute;
    clip: rect( 0,0,0,0 );
    &:checked + .checkbox__graphic {
        &::before,
        &::after {
            display: block;
        }
    }
}
.checkbox__graphic {
    display: block;
    height: 20px;
    width: 20px;
    background: $light-gray;
    border-radius: 2px;
    position: relative;
    margin-left: 8px;
    transform: scale( 0.8 );
    @include tablet {
        transform: scale( 1 );
    }
    &::before,
    &::after {
        @include before;
        bottom: 3px;
        left: 17%;
        transform: translate( -50%, 0 ) rotate( 45deg );
        transform-origin: bottom right;
        background: $dark-gray;
        border-radius: 2px;
        display: none;
    }
    &::before {
        height: 14px;
        width: 4px;
        margin-left: 3px;
    }
    &::after {
        width: 10px;
        height: 4px;
    }
}
.checkbox__label {
    cursor: pointer;
    ~ label {
        cursor: pointer;
    }
}

// Expiration date
input[data-value="exp_month"] {
    width: 30px;
    margin-right: 20px;
}
input[data-value="exp_year"] {
    width: 50px;
}
.wrapper--expiration {
    margin-right: 20px;
    position: relative;
}
.expiration__separator {
    position: absolute;
    height: calc( 100% + 1px );
    width: 20px;
    left: 30px;
    top: 0;
    background: $white;
    color: darken( $light-gray, 8% );
    padding: 6px 0;
    box-sizing: border-box;
    text-align: center;
    opacity: 0;
    &::after {
        content: '/';
    }
    input:focus ~ &,
    .labelize ~ & {
        opacity: 1;
    }
    .error & {
        border-bottom: 1px solid $negative;
    }
}
.wrapper--cvc {
    margin-right: 20px;
    width: 100px;
}

// Input with icon
.input__wrapper--with-icon {
    @extend .input__wrapper;
}
.input--with-icon {
    padding: 10px;
    height: 100%;
    &:focus {
        outline: none;
    }
}
.input--with-icon--left {
    @extend .input--with-icon;
    padding-left: 30px;
    @include medium-phone {
        padding-left: 48px;
    }
}
.input--with-icon--right {
    @extend .input--with-icon;
    padding-right: 30px;
    @include medium-phone {
        padding-right: 48px;
    }
}
.input--with-icon--both {
    @extend .input--with-icon;
    padding: 10px 30px;
    @include medium-phone {
        padding: 10px 48px;
    }
}
.right-icon,
.left-icon {
    position: absolute;
    top: 50%;
    transform: translate( 0, -50% );
    z-index: 0;
}
.left-icon {
    left: 8px;
    @include medium-phone {
        left: 20px;
    }
}
.right-icon {
    right: 8px;
    @include medium-phone {
        right: 20px;
    }
}

.screenreader {
    display: none;
    @media screenreader {
        display: block;
    }
}


// Input validation
.validate-wrapper {
    position: relative;
    .message__text {
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 2px;
        &.message--no-edit {
            display: block;
            padding: 2px;
            box-shadow: 0 2px 4px transparentize( $black, 0.8 );
            background: $white;
            width: 100%;
            box-sizing: border-box;
            z-index: 1;
        }
    }
    &.error {
        border: 1px solid $negative;
        .message__text {
            color: $negative;
        }
    }
    &.success {
        border: 1px solid $positive;
        .message__text {
            color: $positive;
        }
    }
    &.validate-wrapper--no-border {
        border: 0;
    }
}
