.rating__outer {
    position: relative;
}
.rating__inner {
    .icon {
        padding: 2px;
        color: $light-gray;
    }
    &--active {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate( 0, -50% );
        overflow: hidden;
        width: 0;
        transition: all 0.6s;
        .icon {
            color: $primary;
        }
    }
}
