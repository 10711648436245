.expandable {
    overflow: hidden;
}
.expandable__trigger {
    cursor: pointer;
}
.expandable__content {
    transition: all 0.6s;
    opacity: 0;
    visibility: hidden;
    height: 0;
    .expanded & {
        opacity: 1;
        visibility: visible;
        height: auto;
    }
}
